import { Component, OnInit } from '@angular/core';

import { PRODUCTS } from './config/products';
import { Product } from './models/product';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  products = PRODUCTS;
  readonly INTERVAL: number = 4; // minutes
  isCrash: boolean = false;
  crashAudio = new Audio('/assets/audios/crash.mp3');


  ngOnInit(): void {
    this.randomizePrices(this.products, false);

    setInterval(
      () => this.randomizePrices(this.products),
      this.INTERVAL * 60 * 1000
    );
  }

  private randomizePrices(products: Product[], withDelay = true): void {
    for (const product of products) {
      setTimeout(
        () => this.randomizePrice(product),
        withDelay ? this.ramdomNumberBetween(0, this.INTERVAL / 2 * 60 * 1000) : 0
      );
    }
  }

  private randomizePrice(product: Product): void {
    const oldPrice = product.price;
    product.price = this.ramdomNumberBetween(product.minPrice, product.maxPrice);

    if (oldPrice && oldPrice > product.price) product.variation = 'DOWN';
    else product.variation = 'UP';
  }

  private ramdomNumberBetween(min: number, max: number): number {
    min *= 10;
    max *= 10;

    return (Math.floor(Math.random() * (max - min + 1)) + min) / 10;
  }

  toggleCrash(): void {
    this.isCrash = !this.isCrash;

    if (this.isCrash) this.playCrashAudio();
    else this.crashAudio.pause();
  }

  private playCrashAudio(): void {
    this.crashAudio.load();
    this.crashAudio.loop = true
    this.crashAudio.play();
  }
}

<div class="body">
  <img
    (click)="toggleCrash()"
    [src]="'assets/images/crash.svg'"
    class="crash"
  >

  <marquee
    direction="left"
    scrollamount="13"
    [class.isCrash]="isCrash"
  >
    La bourse
  </marquee>

  <div
    *ngFor="let product of products"
    class="item"
  >
    <img [src]="'assets/images/products/' + product.image">
    <h3>{{ product?.name }}</h3>
    <h2 class="random1">
      <span class="price">
        <span
          *ngIf="!isCrash && product?.variation === 'UP'"
          class="arrow green"
        >▲</span>
        <span
          *ngIf="isCrash || product?.variation === 'DOWN'"
          class="arrow red"
        >▼</span>
        {{ isCrash ? product.crashPrice : product.price }}
      </span>
      eur
    </h2>
  </div>
</div>
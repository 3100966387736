import { Product } from '../models/product';

export const PRODUCTS: Product[] = [{
  name: 'Jupiler', image: 'biere.svg',
  crashPrice: 0.6, minPrice: 0.7, maxPrice: 1.3
}, {
  name: 'Delta', image: 'biere.svg',
  crashPrice: 2.2, minPrice: 2.5, maxPrice: 3.7
}, {
  name: 'Desperados', image: 'desperados.svg',
  crashPrice: 1.5, minPrice: 1.8, maxPrice: 2.5
}, {
  name: 'Sangria', image: 'sangria.svg',
  crashPrice: 1, minPrice: 1.2, maxPrice: 2
}, {
  name: 'Kriek', image: 'biere.svg',
  crashPrice: 1.5, minPrice: 1.8, maxPrice: 2.5
}, {
  name: 'Soda', image: 'soda.svg',
  crashPrice: 0.6, minPrice: 0.7, maxPrice: 1.3
}, {
  name: 'Cava', image: 'cava.svg',
  crashPrice: 7, minPrice: 8, maxPrice: 12
}, {
  name: 'Cocktail', image: 'cocktail.png',
  crashPrice: 2.8, minPrice: 3, maxPrice: 3.7
}, {
  name: 'Planche', image: 'planche.svg',
  crashPrice: 3, minPrice: 3.5, maxPrice: 5
}];